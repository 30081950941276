.btn {
    @apply rounded-md font-bold
}

.btn-primary {
    @apply bg-default 
    hover:shadow-md
    disabled:bg-Grey-light-2 disabled:text-Grey-dark disabled:hover:shadow-none
}
    
.btn-secondary {
    @apply border text-white bg-secondary
    hover:shadow-md
    disabled:bg-Grey-light-2 disabled:text-Grey-dark disabled:hover:shadow-none
}

.btn-ghost-dark {
    @apply
    focus:bg-Grey-light
    disabled:bg-Grey-light-2 disabled:text-Grey-dark disabled:hover:shadow-none
}

.btn-ghost-light {
    @apply bg-Grey-background-2 text-white
    hover:bg-Grey-tint
    focus:bg-Grey-dark
    disabled:bg-Grey-background-2 disabled:text-Grey-dark disabled:hover:shadow-none
}

.btn-medium {
    @apply px-4 py-3.5 
}

.btn-small {
    @apply px-4 py-1.5 
}


  