@tailwind base;
@tailwind components;
@tailwind utilities;
@import '../node_modules/react-big-calendar/lib/css/react-big-calendar.css';


.progress-bar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.focusRing___1airF.carousel__slide-focus-ring { outline: none !important; }


html {
  font-family: 'Figtree', sans-serif;
}

body {
  margin: 0;
  font-family: 'Figtree', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Figtree', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer base {
  ul, ol {
    list-style: revert;
  }
}

#App, #root, body, html {
  width: 100%!important;
}
